<template>
  <div
    class="
      q-pa-md q-gutter-md
      fit
      row
      wrap
      justify-center
      items-start
      content-start
    "
  >
    <q-container v-if="show_info" class="absolute-center text-center">
      <p class="text-h6 text-orange q-ma-lg">Your purchase success!</p>
      <p class="text-h6 text-orange q-ma-lg">
        Check you order status in "My Orders"
      </p>

      <q-btn
        class="text-body q-ma-lg"
        size="lg"
        color="blue"
        :label="$t('Go to My Orders')"
        to="/myorders"
      />
    </q-container>
    <q-container v-else class="absolute-center text-center">
      <!-- <p class="text-h6 text-orange q-ma-lg">Processing your purchase</p> -->
    </q-container>
  </div>
</template>

<style scoped>
</style>

<script>
import { ref, onBeforeMount, onMounted, inject, onBeforeUpdate } from "vue";
import { supabase as s } from "../supabase";
import { useRoute, useRouter } from "vue-router";
import { WEBROOT, APIURL, next_tid, next_token_id, chainscan } from "../web3";
import { loadStripe } from "@stripe/stripe-js";
import ax from "axios";
import { stripe_key } from "../stripe";
import { useQuasar } from "quasar";

export default {
  setup() {
    const id = useRoute().params.id;
    const stripe = ref(null);
    let txn = ref(null);
    const q = useQuasar();
    const user = s.auth.user();
    const show_info = ref(false);
    onBeforeMount(async () => {});

    return {
      stripe,
      txn,
      q,
      user,
      show_info,
      id,
    };
  },
  mounted() {
    this.$nextTick(async () => {
      console.log("call mounted");
      // Code that will run only after the
      // entire view has been rendered

      // check order status
      const { data, error } = await s
        .from("txns")
        .select("*,nfts(*),token(*)")
        .match({ id: this.id });
      if (error) {
        console.log(error);
        return;
      }
      if (data.length < 1) {
        console.log("Error: find order records");
      } else {
        this.txn = data[0];
        console.log("txn", this.txn);
      }
      if (this.txn.status == "MINTED" || this.txn.status == "PAID") {
        alert("Order finished! Please check status from 'My Orders'.");
        console.log("Order finished! Status:", this.txn.status);
        return;
      } else {
        console.log("Go to process payment.");
        this.stripe = await loadStripe(stripe_key);
        // Fetches the payment intent status after payment submission
        this.check_status(data[0]);
      }
    });
  },
  methods: {
    async check_status(tx) {
      console.log("call check_status");

      const clientSecret = new URLSearchParams(window.location.search).get(
        "payment_intent_client_secret"
      );

      if (!clientSecret) {
        return;
      }

      const { paymentIntent } = await this.stripe.retrievePaymentIntent(
        clientSecret
      );

      switch (paymentIntent.status) {
        case "succeeded":
          {
            // update order status to PAID
            const { data, error } = await s
              .from("txns")
              .update({ status: "PAID" })
              .match({ id: this.id });
            if (error) {
              console.log("Set order status error.", error);
              alert(error.message);
            } else {
              console.log("Set order status success.", data);
            }
          }
          console.log("tx:", tx);
          // credit card purchase nft order,  create db nft record, and mint token on chain
          if (tx.type == "CREDIT_CARD") {
            console.log("order type:", tx.type);
            // mint token
            this.q.loading.show({
              message: "Creating NFT for you!",
            });
            const mintto = tx.to;
            const tid = tx.tid;
            const tokenid = await next_token_id(tx.contract);

            // database insert token record
            {
              const { data, error } = await s.from("tokens").insert([
                {
                  owner: mintto,
                  balance: 1,
                  data: tx.nfts.data,
                  tokenid: tokenid,
                  tid: tid,
                  contract: tx.contract,
                  uowner: tx.uto
                },
              ]);
              if (error) {
                console.log(error);
              } else {
                console.log(data);
                this.q.loading.show({
                  message: "Create NFT success",
                });
              }
            }

            // mint token on chain
            this.q.loading.show({
              message:
                "Mint your NFT to your wallet, it will take several minutes, please don't leave the page!",
            });

            const url = APIURL + "/i/mint_token";
            const params = {
              to: mintto,
              contract: tx.contract,
              amount: 1,
              tid: tid,
              tokenid: tokenid,
              uuid: this.user.id,
              err:'no'
            };
            const options = {
              method: "POST",
              headers: {
                "content-type": "application/json",
              },
              data: params,
              url,
            };
            console.log("mint NFT req:", options);
            ax(options)
              .then(async (res) => {
                console.log("mint NFT api response:", res);
                if (res.data.blockHash != undefined) {
                  // record transaction record
                  console.log("blockchain txn receipt:", res);
                  {
                    const { data, error } = await s
                      .from("txns")
                      .update({ txn: res })
                      .match({ id: this.id });
                    if (error) {
                      console.log("Set order blockchain txn error.", error);
                      alert(error.message);
                    } else {
                      console.log("Set order blockchain txn success.", data);
                    }

                    // {
                    //   // update order status to DONE
                    //   const { data, error } = await s
                    //     .from("txns")
                    //     .update({ status: "DONE" })
                    //     .match({ id: this.id });
                    //   if (error) {
                    //     console.log("Set order status error.", error);
                    //     alert(error.message);
                    //   } else {
                    //     console.log("Set order status success.", data);
                    //   }
                    // }
                  }
                } else {
                  console.log("ERR:blockchain transaction");
                }
              })
              .then(() => {
                this.q.loading.hide();
                this.q.notify(
                  "Mint 1 NFT of " +
                    tx.nfts.data.name +
                    " to " +
                    mintto +
                    " SUCCESS!"
                );
                this.show_info = true;
              });
          }
          // purchase membership order, change db only
          else if (tx.type == "MEMBER") {
            // update profile level
            let yourlevel = tx.token.tokenid;
            const { data, error } = await s
              .from("profiles")
              .update({ ulevel: yourlevel })
              .match({ id: tx.uto });
            if (error) {
              console.log(error);
              this.q.notify(error);
            } else {
              console.log(data);
              this.q.notify(
                "Buy Membership Success! Update your level success!"
              );
              this.$router.push("/");
            }

            // transfer exist token to user

            // const url = APIURL + "/i/transfer";

            // const params = {
            //   to: txn.value.to,
            //   contract: txn.value.contract,
            //   amount: 1,
            //   tid: txn.value.tid,
            //   uuid: user.id,
            // };

            // const options = {
            //   method: "POST",
            //   headers: {
            //     "content-type": "application/json",
            //   },
            //   data: params,
            //   url,
            // };

            // console.log("transfer token req:", options);
            // ax(options)
            //   .then(async (res) => {
            //     console.log("transfer api response:", res);
            //     if (res.data.blockHash != undefined) {
            //       // record transaction record
            //       console.log("blockchain txn receipt:", res);
            //       {
            //         const { data, error } = await s
            //           .from("txns")
            //           .update({ txn: res })
            //           .match({ id: id });
            //         if (error) {
            //           console.log("Set order blockchain txn error.", error);
            //           alert(error.message);
            //         } else {
            //           console.log("Set order blockchain txn success.", data);
            //         }
            //       }
            //     } else {
            //       console.log("ERR:blockchain transaction");
            //     }
            //   })
            //   .then(() => {
            //     q.loading.hide();
            //     q.notify(
            //       "Trasfer 1 Token of " +
            //         txn.value.token.data.name +
            //         " to " +
            //         txn.value.to +
            //         " SUCCESS!"
            //     );
            //     show_info.value = true;
            //   });
          }
          break;
        case "processing":
          alert("Your payment is processing.");
          break;
        case "requires_payment_method":
          alert("Your payment was not successful, please try again.");
          break;
        default:
          alert("Something went wrong.");
          break;
      }
    },
  },
};
</script>